<template>
	<div class="big-screen">
		<div id="app" ref="app">
			<h4>数控机床运维管理系统V1.0</h4>
			<img class="new_times" src="../../src/assets/img/logo.png" alt="" />
			<div class="new_time" @click="backFun">{{timeText}}</div>
			<div class="group" style="margin-top: 10px;">
				<el-row :gutter="20">
					<el-col :span="6">
						<div class="group1">
							<div class="group1_1">我的设备</div>
							<div class="list">
								<div class="item">
									<countTo style="font-size: 30px; line-height: 50px;" :startVal='0' :endVal='numObj.deviceNum ? numObj.deviceNum : 0' :duration='2000'></countTo>
									<div>入网设备数量</div>
								</div>
								<div class="item">
									<div>{{ numObj.gatewayNum ? numObj.gatewayNum : 0 }}</div>
									<div>已安装网管</div>
								</div>
								<div class="item">
									<div>{{ numObj.sensorNum ? numObj.sensorNum : 0 }}</div>
									<div>传感器</div>
								</div>
								<div class="item">
									<div>{{ numObj.sensorProNum ? numObj.sensorProNum : 0 }}</div>
									<div>传感器pro</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="group1">
							<div class="group1_1">设备情况</div>
							<div id="main999" style="width: 100%; height: 300px"></div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="group1">
							<div class="group1_1_1">
								<span class="group1_1">稼动率，可动率</span>
								<div class="group1_1 boxnew">
									<span class="span1" @click="getRecent">近30天</span>
									<span>自定义
										<div class="datebox">
											<el-date-picker v-model="value8" type="daterange" range-separator="至" start-placeholder="开始日期"
												end-placeholder="结束日期" format="yyyy - MM - dd" value-format="yyyy-MM-dd" @change="changeFun">
											</el-date-picker>
										</div>
									</span>
								</div>
							</div>
							<div id="main1" style="width: 340px; height: 300px;margin: 0 auto;"></div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="group1">
							<div class="group1_1">硬件使用情况</div>
							<div class="group1_2">
								<div class="progress">
									<el-progress color="#54BEEF" :text-inside="true" :stroke-width="15" :percentage="rate1"></el-progress>
									<div>网关使用率</div>
								</div>
								<div class="progress">
									<el-progress color="#F28B33" :text-inside="true" :stroke-width="15" :percentage="rate2"></el-progress>
									<div>传感器使用率</div>
								</div>
								<!-- <div class="progress">
                  <el-progress
                    color="#0735C3"
                    :text-inside="true"
                    :stroke-width="15"
                    :percentage="70"
                  ></el-progress>
                  <div>传感器使用率</div>
                </div> -->
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="group2">
				<el-row :gutter="20">
					<el-col :span="6">
						<div class="group2_1">
							<h5>保养计划</h5>
							<div class="table-box">
								<div class="table-1">
									<table>
										<tr>
											<th>设备编号</th>
											<!-- <th>车间</th> -->
											<th>保养时间</th>
										</tr>
										<tr v-for="(item, index) in baoList" :key="index">
											<td>{{ item.deviceNumber ? item.deviceNumber : "-" }}</td>
											<!-- <td>{{ item.workshopName ? item.workshopName : "-" }}</td> -->
											<td>{{ item.planningTime ? item.planningTime : "-" }}</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="group2_1 group2_10">
							<h5>报警信息</h5>
							<div class="table-box">
								<div class="table-1">
									<table>
										<tr>
											<th>报警号</th>
											<th>报警信息</th>
											<th>报警时间</th>
										</tr>
										<tr v-for="(item, index) in alarmArr" :key="index">
											<td>{{ item.alarmNo ? item.alarmNo : "-" }}</td>
											<td>{{ item.alarmText ? item.alarmText : "-" }}</td>
											<td>{{ item.report_time ? item.report_time : "-" }}</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="group2_1">
							<h5>进行中订单</h5>
							<div class="table-box">
								<div class="table-1">
									<table>
										<tr>
											<th>订单编号</th>
											<th>开始时间</th>
										</tr>
										<tr v-for="(item, index) in doingOrder" :key="index">
											<td>{{ item.orderNum ? item.orderNum : "-" }}</td>
											<td>{{ item.createTime ? item.createTime : "-" }}</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="group3">
				<el-row :gutter="20">
					<el-col :span="4" style="margin-bottom: 12px" v-for="(item, index) in dataShow111" :key="index">
						<div class="group3_1">
							<div class="group3_2">
								<div class="bold"></div>
								<div class="group3_3">
									<el-radio-group v-model="radio1" fill="#38CC41">
										<!-- <div>
                      <div>传</div>
                      <div>传</div>
                    </div> -->
										<el-radio-button size="mini" label="传"></el-radio-button>
										<el-radio-button size="mini" label="关"></el-radio-button>
									</el-radio-group>
								</div>
							</div>
							<div class="group3_4">
								<div class="group3_5">
									<div class="txtbox">
										设备编号：{{ item.deviceNumber ? item.deviceNumber : "-" }}
									</div>
									<div>
										设备型号：{{ item.deviceModel ? item.deviceModel : "-" }}
									</div>
									<div class="pinpai">
										设备品牌：{{ item.brandName ? item.brandName : "-" }}
									</div>
									<div>
										开机时间：{{ item.powerTime ? item.powerTime : "-" }}
									</div>
									<div>加工时间：{{ item.workTime ? item.workTime : "-" }}</div>
								</div>
							</div>
							<div class="group3_6">
								<!-- v-if="item.data[0] && item.data[0].acceleration" -->
								<div  :id="'main' + item.id" class="group3_7"></div>
								<!-- <div v-else class="group3_7">
									<el-empty description=""></el-empty>
								</div> -->
							</div>
						</div>
					</el-col>
					<!-- <el-col :span="4">
            <div class="group3_1">
              <div class="group3_2">
                <div class="bold"></div>
                <div class="group3_3">
                  <el-radio-group v-model="radio1">
                    <el-radio-button size="mini" label="传"></el-radio-button>
                    <el-radio-button size="mini" label="关"></el-radio-button>
                  </el-radio-group>
                </div>
              </div>
              <div class="group3_4">
                <div class="group3_5">
                  <div>设备编号：3PVW3DA5GK</div>
                  <div>设备型号：x12</div>
                  <div>设备品牌：-</div>
                  <div>开机时间：2023-9-12</div>
                  <div>加工时间：2023-9-08</div>
                </div>
              </div>
              <div class="group3_6">
                <div id="main1" class="group3_7"></div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="group3_1">
              <div class="group3_2">
                <div class="bold"></div>
                <div class="group3_3">
                  <el-radio-group v-model="radio1">
                    <el-radio-button size="mini" label="传"></el-radio-button>
                    <el-radio-button size="mini" label="关"></el-radio-button>
                  </el-radio-group>
                </div>
              </div>
              <div class="group3_4">
                <div class="group3_5">
                  <div>设备编号：3PVW3DA5GK</div>
                  <div>设备型号：x12</div>
                  <div>设备品牌：-</div>
                  <div>开机时间：2023-9-12</div>
                  <div>加工时间：2023-9-08</div>
                </div>
              </div>
              <div class="group3_6">
                <div id="main7" class="group3_7"></div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="group3_1">
              <div class="group3_2">
                <div class="bold"></div>
                <div class="group3_3">
                  <el-radio-group v-model="radio1">
                    <el-radio-button size="mini" label="传"></el-radio-button>
                    <el-radio-button size="mini" label="关"></el-radio-button>
                  </el-radio-group>
                </div>
              </div>
              <div class="group3_4">
                <div class="group3_5">
                  <div>设备编号：3PVW3DA5GK</div>
                  <div>设备型号：x12</div>
                  <div>设备品牌：-</div>
                  <div>开机时间：2023-9-12</div>
                  <div>加工时间：2023-9-08</div>
                </div>
              </div>
              <div class="group3_6">
                <div id="main11" class="group3_7"></div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="group3_1">
              <div class="group3_2">
                <div class="bold"></div>
                <div class="group3_3">
                  <el-radio-group v-model="radio1" fill="#38CC41">
                    <el-radio-button size="mini" label="传"></el-radio-button>
                    <el-radio-button size="mini" label="关"></el-radio-button>
                  </el-radio-group>
                </div>
              </div>
              <div class="group3_4">
                <div class="group3_5">
                  <div>设备编号：3PVW3DA5GK</div>
                  <div>设备型号：x12</div>
                  <div>设备品牌：-</div>
                  <div>开机时间：2023-9-12</div>
                  <div>加工时间：2023-9-08</div>
                </div>
              </div>
              <div class="group3_6">
                <div id="main6" class="group3_7"></div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="group3_1">
              <div class="group3_2">
                <div class="bold"></div>
                <div class="group3_3">
                  <el-radio-group v-model="radio1" fill="#38CC41">
                    <el-radio-button size="mini" label="传"></el-radio-button>
                    <el-radio-button size="mini" label="关"></el-radio-button>
                  </el-radio-group>
                </div>
              </div>
              <div class="group3_4">
                <div class="group3_5">
                  <div>设备编号：3PVW3DA5GK</div>
                  <div>设备型号：x12</div>
                  <div>设备品牌：-</div>
                  <div>开机时间：2023-9-12</div>
                  <div>加工时间：2023-9-08</div>
                </div>
              </div>
              <div class="group3_6">
                <div id="main5" class="group3_7"></div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="group3_1">
              <div class="group3_2">
                <div class="bold"></div>
                <div class="group3_3">
                  <el-radio-group v-model="radio1" fill="#38CC41">
                    <el-radio-button size="mini" label="传"></el-radio-button>
                    <el-radio-button size="mini" label="关"></el-radio-button>
                  </el-radio-group>
                </div>
              </div>
              <div class="group3_4">
                <div class="group3_5">
                  <div>设备编号：这里是编号</div>
                  <div>设备型号：这里是编号</div>
                  <div>设备品牌：这里是编号</div>
                  <div>开机时间：这里是编号</div>
                  <div>加工时间：这里是编号</div>
                </div>
              </div>
              <div class="group3_6">
                <div id="main4" class="group3_7"></div>
              </div>
            </div>
          </el-col> -->
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from "echarts";
	import countTo from 'vue-count-to';
	export default {
		components:{
			countTo
		},
		data() {
			return {
				list: [{
						name: "客户数",
						num: 30
					},
					{
						name: "订单数",
						num: 30
					},
					{
						name: "入网设备数量",
						num: 30
					},
					{
						name: "基地",
						num: 30
					},
					{
						name: "加盟商",
						num: 30
					},
					{
						name: "技术专家",
						num: 30
					},
					{
						name: "基地工程师",
						num: 30
					},
					{
						name: "加盟商工程师",
						num: 30
					},
					{
						name: "维保知识库",
						num: 30
					},
					{
						name: "大修改造案例",
						num: 30
					},
				],
				numbers: [{
						name: "客户数",
						num: 0
					},
					{
						name: "订单数",
						num: 0
					},
					{
						name: "入网设备数量",
						num: 0
					},
					{
						name: "基地",
						num: 0
					},
					{
						name: "加盟商",
						num: 0
					},
					{
						name: "技术专家",
						num: 0
					},
					{
						name: "基地工程师",
						num: 0
					},
					{
						name: "加盟商工程师",
						num: 0
					},
					{
						name: "维保知识库",
						num: 0
					},
					{
						name: "大修改造案例",
						num: 0
					},
				], // 初始值数组
				duration: 2000,
				// 当前动画进度
				progress: 0,
				// 定时器 ID
				timerId: null,
				radio1: "",
				alarmArr: [], //报警信息
				companyId: "",
				numObj: {},
				doingOrder: [],
				baoList: [],
				rate1: "",
				rate2: "",
				hitObj: {},
				statusObj: {},
				maintenancesObj: {},
				startTime: "",
				endTime: "",
				value8: "",
				sTime: "", //开始时间
				eTime: "", //结束时间
				dataShow11: {},
				dataShow111: {},
				timeText: '',
			};
		},
		beforeDestroy() {
			if (this.timerId) {
				clearInterval(this.timerId);
			}
		},
		mounted() {
			
			this.screenComputed()
			this.getWordInit();
			// this.startAnimation();
			// 底部6个展示
			// 分页列表查询
			this.getAllList();
			this.setPie();
			this.setPieRight();
		},
		created() {
			this.getAllComp();
			this.getTimes()
		},
		methods: {
			screenComputed(){
				if(!window.bigScrrenFun){
					// window.bigScrrenFun = window.addEventListener('resize', (e) => {
						
						setTimeout(() => {
							const windowWidth = window.innerWidth;
  							const windowHeight = window.innerHeight;
							let domHeight = this.$refs.app.getBoundingClientRect().height
							let domWidth = this.$refs.app.getBoundingClientRect().width
							this.$refs.app.style.transform = `scale(${windowHeight/domHeight},${windowHeight/domHeight})` 
						}, 4000)
						// })
				}
			},
			getTimes() {
				let datas = new Date();
				let year = datas.getFullYear();
				let month = datas.getMonth() + 1;
				let day = datas.getDate();
				this.timeText = year + '-' + (month < 9 ? '0' + month : month) + '-' + (day < 9 ? '0' + day : day)
				console.log(year, month, day, 11111111111)
			},
			setPieRight() {
				var myChats1 = echarts.init(document.getElementById("main1"));
				var option1 = {
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: "category",
						data: ["2024-04-01", "2024-04-02", "2024-04-03", "2024-04-04", "2024-04-05", "2024-04-06", "2024-04-07"],
						axisLabel: {
							textStyle: {
								color: "#fff",
							},
						},
					},
					yAxis: {
						type: "value",
						axisLabel: {
							textStyle: {
								color: "#fff",
							},
						},
					},
					legend: {
						bottom: "5%",
						left: "center",
						textStyle: {
							color: "#fff",
						},
					},
					series: [{
							data: [55, 60, 70, 65, 67, 75, 79],
							type: "line",
							itemStyle: {
								color: "#1FC0D1"
							},
							name: "稼动率",
						},
						{
							data: [100, 95, 80, 90, 79, 74, 83],
							type: "line",
							itemStyle: {
								color: "#FEEC61"
							},
							name: "可动率",
						},
					],
				};
				// 使用刚指定的配置项和数据显示图表
				myChats1.setOption(option1);
			},
			setPie() {
				var myChats = echarts.init(document.getElementById("main999"));

				var option = {
					title: {
						text: "设备情况",
						left: "center",
						top: "35%",
						textStyle: {
							color: "#fff",
							// fontSize: "20",
							align: "center",
						},
					},
					tooltip: {
						trigger: "item",
					},
					legend: {
						bottom: "5%",
						left: "center",
						textStyle: {
							color: "#fff",
						},
					},
					series: [{
						name: "Access From",
						type: "pie",
						radius: ["40%", "70%"],
						center: ["50%", "40%"],
						avoidLabelOverlap: false,
						label: {
							show: true,
							position: "left",
						},
						emphasis: {
							label: {
								show: true,
								fontSize: 20,
								fontWeight: "bold",
							},
						},
						labelLine: {
							show: false,
						},
						// data: this.statusObj,
						data: [{
								value: 3,
								name: "运行"
							},
							{
								value: 1,
								name: "维修"
							},
							{
								value: 0,
								name: "闲置"
							},
							{
								value: 0,
								name: "关机"
							},
						],
					}, ],
				};
				myChats.setOption(option);
			},
			// 获取最近30天
			getRecent() {
				// 获取当前日期
				var currentDate = new Date();

				// 获取本月的第一天
				var firstDay = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					1
				);

				// 获取本月的最后一天
				var lastDay = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth() + 1,
					0
				);

				// 格式化日期，将日期格式转换为 'yyyy-mm-dd' 的形式
				function formatDate(date) {
					var year = date.getFullYear();
					var month = (date.getMonth() + 1).toString().padStart(2, "0"); // 月份从0开始，需要加1，并补齐两位
					var day = date.getDate().toString().padStart(2, "0"); // 补齐两位
					return `${year}-${month}-${day}`;
				}

				this.sTime = formatDate(firstDay);
				this.eTime = formatDate(lastDay);
				this.iotGetMaintainFrequency();
			},
			// 时间改变函数
			changeFun() {
				if (this.value8 && this.value8.length) {
					this.sTime = this.value8[0];
					this.eTime = this.value8[1];
				} else {
					this.sTime = "";
					this.eTime = "";
				}
				// 获取图表数据
				this.iotGetMaintainFrequency();
			},
			backFun() {
				this.$router.go(-1); // 返回上一页
			},
			getAllComp() {
				this.$API
					.getUserComp()
					.then((res) => {
						if (res.code == 1) {
							let userCompMsg = res.data;
							this.companyId = userCompMsg[0].id;
							// 获取报警记录数据
							this.getAlarmData();
							// 数据大屏数量统计
							this.getNumData();
							// 数据大屏进行中订单
							this.getDoingOrderData();
							// 数据大屏保养计划列表
							this.getDoingBaoListData();
							// 数据大屏iot设备使用率
							this.getDoingUseRateData();
							// 数据大屏iot碰撞缓冲报警
							this.getScreenGetDeviceStatus();
							// 获取设备维修率列表
							this.getScreenDeviceMaintenances();
							// 获取设备维修频率
							this.iotGetMaintainFrequency();
							// 获取设备维修率列表
							this.iotGetDataScreen();
						} else {
							// this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 分页列表查询
			getAllList() {
				let params = {
					deviceOnlyCode: "",
					// pageNo: this.page,
					// pageSize: this.pageSize,
					// startTime: this.startTime,
					// endTime: this.endTime,
					// status: Number(this.value),
					// alarmNo: this.input,
					// id: Number(this.id),
				};
				this.$API
					.iotDataPreList(params)
					.then((res) => {
						if (res.code == 1) {
							// 数据大屏iot碰撞缓冲报警
							this.getScreenGetHitAlarm();
							// this.tableData = [];
							// this.listTotal = Number(res.data.total);
							// this.tableData = res.data.records;
						} else {
							// this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 获取设备维修率列表
			iotGetDataScreen() {
				this.$API
					.iotGetDataScreen({
						// companyId: this.companyId,
						companyId: 2,
					})
					.then((res) => {
						if (res.code == 1) {
							this.dataShow111 = res.data;
							console.log(this.dataShow111, "===maintenancesObj");

							this.$nextTick(() => {
								this.dataShow111.forEach((item) => {
								let xdata = [];
								let ydata = [];
								if(item.data[0] && item.data[0].acceleration){
									console.log(item.data[0].acceleration.data, '999999999999999999999999999');
									item.data[0].acceleration.data.forEach(item =>{
										xdata.push(item[0])
										ydata.push(item[1])

									})
								}
									this.creteImg(item.id,xdata,ydata);
								});
							});

							// this.getWordInit();
						} else {
							// this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			creteImg(id,xdata, ydata) {
				console.log(id, "====id===",xdata , ydata, '======================');

				var myChats1 = echarts.init(document.getElementById("main" + id));
				console.log(myChats1, "===myChats1");

				var option4 = {
					grid: {
						left: "3%", // 图表内容距离容器左侧的距离
						right: "4%", // 图表内容距离容器右侧的距离
						top: "10%", // 图表内容距离容器顶部的距离
						bottom: "10%", // 图表内容距离容器底部的距离
						containLabel: true, // 是否包含坐标轴的刻度标签在内
					},
					xAxis: {
						type: "category",
						data: xdata ? xdata : [],
						axisLabel: {
							textStyle: {
								color: "#fff",
							},
						},
					},
					yAxis: {
						type: "value",
						axisLabel: {
							textStyle: {
								color: "#fff",
							},
						},
					},
					series: [{
							name: "Email",
							type: "line",
							stack: "Total",
							data: ydata ? ydata : [],
						}
					],
				};
				myChats1.setOption(option4);
			},
			// 获取设备维修率列表
			iotGetMaintainFrequency() {
				this.$API
					.iotGetMaintainFrequency({
						companyId: this.companyId,
						startDate: this.sTime,
						endDate: this.eTime,
					})
					.then((res) => {
						if (res.code == 1) {
							this.dataShow11 = res.data;
							// this.statusObj = this.statusObj.map((item) => {
							//   item.value = item.num;
							//   item.name =
							//     item.status == 1
							//       ? "运行中"
							//       : item.status == 2
							//       ? "闲置"
							//       : item.status == 3
							//       ? "关机"
							//       : "维修中";
							//   return { ...item };
							// });
							console.log(this.dataShow11, "===maintenancesObj");
							this.statusObj = [{
									value: 53,
									name: "关机",
								},
								{
									value: 70,
									name: "维修中",
								},
								{
									value: 20,
									name: "运行中",
								},
								{
									value: 13,
									name: "闲置",
								},
							];
							this.getWordInit();
						} else {
							// this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 获取设备维修率列表
			getScreenDeviceMaintenances() {
				this.$API
					.screenDeviceMaintenance({
						companyId: this.companyId,
						startDate: this.startTime,
						endDate: this.endTime,
					})
					.then((res) => {
						if (res.code == 1) {
							this.maintenancesObj = res.data;
							// this.statusObj = this.statusObj.map((item) => {
							//   item.value = item.num;
							//   item.name =
							//     item.status == 1
							//       ? "运行中"
							//       : item.status == 2
							//       ? "闲置"
							//       : item.status == 3
							//       ? "关机"
							//       : "维修中";
							//   return { ...item };
							// });
							console.log(this.maintenancesObj, "===maintenancesObj");

							this.getWordInit();
						} else {
							// this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 数据大屏iot碰撞缓冲报警
			getScreenGetDeviceStatus() {
				this.$API
					.screenGetDeviceStatus({
						companyId: this.companyId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.statusObj = res.data;
							this.statusObj = this.statusObj.map((item) => {
								item.value = item.num;
								item.name =
									item.status == 1 ?
									"运行中" :
									item.status == 2 ?
									"闲置" :
									item.status == 3 ?
									"关机" :
									"维修中";
								return {
									...item
								};
							});
							console.log(this.statusObj, "===statusObj");

							this.getWordInit();
						} else {
							// this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 数据大屏iot碰撞缓冲报警
			getScreenGetHitAlarm() {
				this.$API
					.screenGetHitAlarm({
						// companyId: this.companyId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.hitObj = res.data;
						} else {
							// this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 数据大屏iot设备使用率
			getDoingUseRateData() {
				this.$API
					.screenGetUseRate({
						companyId: this.companyId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.useRateData = res.data;
							this.rate1 = Number(this.useRateData.gatewayOnlineNum) ?
								(Number(this.useRateData.gatewayOnlineNum) /
									Number(this.useRateData.gatewayAllNum)) *
								100 :
								0;
							this.rate2 = Number(this.useRateData.sensorOnlineNum) ?
								(Number(this.useRateData.sensorOnlineNum) /
									Number(this.useRateData.sensorAllNum)) *
								100 :
								0;
							this.rate1 = 100;
							this.rate2 = 100;
						} else {
							// this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 数据大屏保养计划列表
			getDoingBaoListData() {
				this.$API
					.screenGetBaoList({
						companyId: this.companyId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.baoList = res.data.records;
							this.baoList = [{
									deviceNumber: "No8fd8955",
									workshopName: "一车间",
									planningTime: "2024-05-12",
								},
								{
									deviceNumber: "No8895555",
									workshopName: "一车间",
									planningTime: "2024-06-12",
								},
								{
									deviceNumber: "No88dsf955",
									workshopName: "一车间",
									planningTime: "2024-05-22",
								},
							];
						} else {
							// this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 数据大屏进行中订单
			getDoingOrderData() {
				this.$API
					.screenGetDoingOrder({
						companyId: this.companyId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.doingOrder = res.data.records;
						} else {
							// this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 数据大屏数量统计
			getNumData() {
				this.$API
					.screenGetNum({
						companyId: this.companyId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.numObj = res.data;
							this.numObj.deviceNum = 6;
							this.numObj.gatewayNum = 2;
							this.numObj.sensorNum = 1;
							this.numObj.sensorProNum = 1;
						} else {
							// this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 获取报警记录数据
			getAlarmData() {
				this.$API
					.screenGetAlarm({
						companyId: this.companyId,
					})
					.then((res) => {
						if (res.code == 1) {
							this.alarmArr = res.data;
							this.alarmArr = [{
									alarmNo: "1012",
									alarmText: "[通道%1：]系统错误%2%3%4",
									report_time: "2023-5",
								},
								{
									alarmNo: "1013",
									alarmText: "[通道%1：]系统错误%2%3%4",
									report_time: "2023-8",
								},
								{
									alarmNo: "1016",
									alarmText: "[通道 %1:]轴 %2 系统错误 %3%4",
									report_time: "2023-9",
								},
								{
									alarmNo: "1031",
									alarmText: "联接模块出错无说明%1 NCU %2 %3 %4",
									report_time: "2023-9",
								},
								{
									alarmNo: "1019",
									alarmText: "「通道 %1:1任务 %2浮点处理器状态 %4 在地址 %3 浮点计算出错",
									report_time: "2023-10",
								},
								{
									alarmNo: "1030",
									alarmText: "系统出错在母线模式错误代码 %1 错误类型%2",
									report_time: "2023-10",
								},
								{
									alarmNo: "1017",
									alarmText: "[通道 %1:]轴 %2 系统错误 %3 %4",
									report_time: "2023-12",
								},
								{
									alarmNo: "1018",
									alarmText: "「[通道 %1:1任务 %2站 %3 浮点处理器状态: %4 中浮点计算出错",
									report_time: "2024-02",
								},
								{
									alarmNo: "1012",
									alarmText: "[通道 %1:]系统错误%2 %3 %4",
									report_time: "2024-03",
								},
							];
						} else {
							// this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			startAnimation() {
				// 清除可能存在的旧定时器
				if (this.timerId) {
					clearInterval(this.timerId);
				}
				// 计算每一步的增量
				const increment = this.list.map((target) => 1);
				console.log(increment);
				// 设置定时器
				this.timerId = setInterval(() => {
					// 更新进度
					this.progress += 1;
					// 如果进度超过或等于持续时间，则停止动画
					if (this.progress >= this.duration) {
						clearInterval(this.timerId);
						this.timerId = null;
						return;
					}
					// 更新当前值
				}, 1000); // 每100毫秒更新一次
			},
			getWordInit() {
				return;
				console.log(myChats, "////myChats99");

				var myChats1 = echarts.init(document.getElementById("main1"));
				// var myChats2 = echarts.init(document.getElementById('main2'))
				var myChats3 = echarts.init(document.getElementById("main3"));
				var myChats4 = echarts.init(document.getElementById("main4"));
				var myChats5 = echarts.init(document.getElementById("main5"));
				var myChats6 = echarts.init(document.getElementById("main6"));
				var myChats7 = echarts.init(document.getElementById("main7"));
				// var myChats8 = echarts.init(document.getElementById('main8'))
				// var myChats9 = echarts.init(document.getElementById('main9'))
				// var myChats10 = echarts.init(document.getElementById('main10'))
				var myChats11 = echarts.init(document.getElementById("main11"));
				// var myChats12 = echarts.init(document.getElementById('main12'))

				var option1 = {
					xAxis: {
						type: "category",
						data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
						axisLabel: {
							textStyle: {
								color: "#fff",
							},
						},
					},
					yAxis: {
						type: "value",
						axisLabel: {
							textStyle: {
								color: "#fff",
							},
						},
					},
					legend: {
						bottom: "5%",
						left: "center",
						textStyle: {
							color: "#fff",
						},
					},
					series: [{
							data: [150, 230, 224, 218, 135, 147, 260],
							type: "line",
							itemStyle: {
								color: "#1FC0D1"
							},
							name: "振动率",
						},
						{
							data: [200, 240, 274, 218, 105, 107, 290],
							type: "line",
							itemStyle: {
								color: "#FEEC61"
							},
							name: "可动率",
						},
					],
				};
				// 使用刚指定的配置项和数据显示图表
				myChats1.setOption(option1);
				var option2 = {
					xAxis: {
						type: "category",
						data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
						axisLabel: {
							textStyle: {
								color: "#fff",
							},
						},
					},
					yAxis: {
						type: "value",
						axisLabel: {
							textStyle: {
								color: "#fff",
							},
						},
					},
					series: [{
						data: [120, 200, 150, 80, 70, 110, 130],
						type: "bar",
						itemStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: "rgba(2, 126, 220)"
									}, // 红色，完全不透明
									{
										offset: 1,
										color: "#00D6FD"
									}, // 蓝色，半透明
								]),
							},
						},
					}, ],
				};
				// myChats2.setOption(option2);
				var option3 = {
					grid: {
						left: "3%", // 图表内容距离容器左侧的距离
						right: "4%", // 图表内容距离容器右侧的距离
						top: "10%", // 图表内容距离容器顶部的距离
						bottom: "10%", // 图表内容距离容器底部的距离
						containLabel: true, // 是否包含坐标轴的刻度标签在内
					},
					xAxis: {
						type: "category",
						data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
						axisLabel: {
							textStyle: {
								color: "#fff",
							},
						},
					},
					yAxis: {
						type: "value",
						axisLabel: {
							textStyle: {
								color: "#fff",
							},
						},
					},
					series: [{
						data: [820, 932, 901, 934, 1290, 1330, 1320],
						type: "line",
						smooth: true,
					}, ],
				};
				var option4 = {
					grid: {
						left: "3%", // 图表内容距离容器左侧的距离
						right: "4%", // 图表内容距离容器右侧的距离
						top: "10%", // 图表内容距离容器顶部的距离
						bottom: "10%", // 图表内容距离容器底部的距离
						containLabel: true, // 是否包含坐标轴的刻度标签在内
					},
					xAxis: {
						type: "category",
						data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
						axisLabel: {
							textStyle: {
								color: "#fff",
							},
						},
					},
					yAxis: {
						type: "value",
						axisLabel: {
							textStyle: {
								color: "#fff",
							},
						},
					},
					series: [{
							name: "Email",
							type: "line",
							stack: "Total",
							data: [120, 132, 101, 134, 90, 230, 210],
						},
						{
							name: "Union Ads",
							type: "line",
							stack: "Total",
							data: [220, 182, 191, 234, 290, 330, 310],
						},
						{
							name: "Video Ads",
							type: "line",
							stack: "Total",
							data: [150, 232, 201, 154, 190, 330, 410],
						},
						{
							name: "Direct",
							type: "line",
							stack: "Total",
							data: [320, 332, 301, 334, 390, 330, 320],
						},
						{
							name: "Search Engine",
							type: "line",
							stack: "Total",
							data: [820, 932, 901, 934, 1290, 1330, 1320],
						},
					],
				};
				myChats3.setOption(option4);
				myChats4.setOption(option4);
				myChats5.setOption(option4);
				myChats6.setOption(option4);
				myChats7.setOption(option4);
				// myChats8.setOption(option3);
				// myChats9.setOption(option3);
				// myChats10.setOption(option3);
				myChats11.setOption(option4);
				// myChats12.setOption(option3);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.big-screen {
		overflow: hidden;
		height: 100vh;
	}
	* {
		margin: 0;
		padding: 0;
		color: #fff;
		//   background-color: black;
	}

	.txtbox {
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.datebox {
		width: 0;
		height: 0;

		.el-date-editor {
			opacity: 0 !important;
			width: 40px !important;
			height: 30px !important;
			position: relative !important;
			top: -20px !important;
		}
	}

	.boxnew {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: relative;
		top: -3px;
		cursor: pointer;
	}

	.span1 {
		margin-right: 5px;
	}

	.back {
		margin-bottom: 6px;
		display: flex;
		justify-content: flex-end;
		cursor: pointer;
	}

	#app {
		background-image: url(~@/assets/img/8.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		min-height: 100vh;
		// width: 100%;
		box-sizing: border-box;
		padding: 10px;
		height: auto;
		transform-origin:0% 0%;
	}

	h4 {
		text-align: center;
		color: #fff;
		font-size: 30px;
		background-image: url(~@/assets/img/9.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		line-height: 80px;
	}

	.new_time {
		font-size: 18px;
		margin-left: 40px;
		position: absolute;
		top: 0;
		right: 30px;
		top: 35px;
		right: 30px;
		// line-height: 80px;
		font-size: 20px;
	}

	.new_times {
		font-size: 18px;
		margin-right: 40px;
		position: absolute;
		top: 35px;
		left: 30px;
		line-height: 80px;
		font-size: 20px;
		font-weight: 500;
		font-size: 30px;
		font-weight: 600;
	}


	.group {
		width: 100%;
		box-sizing: border-box;
	}

	.grid-content {
		height: 40px;
		background: red;
	}

	.group1,
	.group2_1 {
		background-image: url(~@/assets/img/17.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		height: 350px;
	}

	.group1_1 {
		height: 40px;
		text-align: left;
		padding-left: 30px;
		line-height: 30px;
	}

	.group1_1_1 {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.group1_1 span {
		display: inline-block;
		width: 60px;
		line-height: 25px;
		background: rgba(255, 255, 255, 0.3);
		font-size: 14px;
		text-align: center;
	}

	.list {
		height: 300px;
		display: flex;
		justify-content: space-around;
		align-items: flex-start;
		flex-wrap: wrap;
		padding: 0 5%;
		box-sizing: border-box;
	}

	.item {
		background-image: url(~@/assets/img/20.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 150px;
		text-align: center;
		height: 150px;
		font-family: RiWenLOGO, RiWenLOGO;
	}

	.item div:nth-child(1) {
		font-size: 30px;
		line-height: 50px;
	}

	.item div:nth-child(2) {
		font-size: 14px;
	}

	/* .item:nth-child(2n){margin-left: 4%;} */
	.group2 {
		margin: 20px 0;
	}

	.group1_2 {
		height: 300px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-direction: column;
	}

	.progress {
		width: 90%;
		margin: 0 auto;
	}

	.progress div {
		line-height: 40px;
		font-size: 20px;
		text-align: center;
		font-weight: bold;
	}

	.group2_10 {
		background-image: url(~@/assets/img/19.png);
		background-repeat: no-repeat;
		background-size: 100%;
	}

	.group2_1 h5 {
		line-height: 35px;
		padding-left: 40px;
		height: 35px;
	}

	.table-box {
		width: 100%;
		height: 300px;
		overflow: hidden;
		border: none;
	}

	.table-1 {
		width: calc(100% + 20px);
		height: 300px;
		overflow-y: scroll;
		overflow-x: auto;
	}

	.table {
		width: 100%;
		height: 300px;
	}

	table {
		width: 100%;
	}

	table tr {
		line-height: 40px;
		text-align: center;
	}

	table tr th {
		font-size: 15px;
	}

	table tr:nth-child(2n) {
		background: rgba(10, 205, 149, 0.2);
	}

	.group3_1 {
		background-image: url(~@/assets/img/18.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding: 10px 0;
	}

	.group3_2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;
	}

	.group3_2 .bold {
		width: 30px;
		height: 30px;
		background: #58c33e;
		border-radius: 50%;
	}

	.group3_4 {
		padding: 20px 20px 0;
	}

	.group3_5 {
		line-height: 40px;
	}


	.group3_5 div {
		text-align: left;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.group3_6 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: 10px;
	}

	.group3_7 {
		width: 100%;
		background-image: url(~@/assets/img/18.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		height: 200px;
		margin-bottom: 20px;
	}

	.el-progress__text {
		color: #fff !important;
	}

	// }
</style>